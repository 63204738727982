<template>
  <div>
    <Header />
    <div class="schedule-page">
      <div class="overlay">
        <h1>Agendamento de Aulas</h1>
        <h2>Escolha seu professor e agende sua aula via WhatsApp</h2>
        <div class="teachers">
          <div class="teacher">
            <h3>Uilliam Pinheiro</h3>
            <h4>Beach Tennis</h4>
            <p>Professor com mais de 10 anos de experiência em Beach Tennis. Instrutor certificado e atleta de nível nacional.</p>
            <a :href="`https://wa.me/5535999891384?text=Olá,%20gostaria%20de%20agendar%20uma%20aula%20de%20Beach%20Tennis`" target="_blank">
              <button>AGENDAR AULA VIA WHATSAPP</button>
            </a>
          </div>
          <div class="teacher">
            <h3>Everson Balisa</h3>
            <h4>Futvôlei</h4>
            <p>Especialista em futvôlei, Everson treina atletas amadores e profissionais. Conhecido por seu estilo de jogo técnico e estratégico.</p>
            <a :href="`https://wa.me/5531984392808?text=Olá,%20gostaria%20de%20agendar%20uma%20aula%20de%20Futvôlei`" target="_blank">
              <button>AGENDAR AULA VIA WHATSAPP</button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default defineComponent({
  name: "SchedulePage",
  components: {
    Header,
    Footer,
  },
});
</script>

<style scoped>
.schedule-page {
  position: relative;
  width: 100%;
  background: url('@/assets/quadras.jpg') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.overlay {
  position: relative;
  width: 100%;
  padding: 40px 20px;
  min-height: 70vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1, h2 {
  color: white;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 40px;
  text-align: center;
}

.teachers {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  max-width: 900px;
  width: 100%;
}

.teacher {
  background: linear-gradient(to top, #f01f84, #FF6A00);
  color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  text-align: left;
  width: 300px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.teacher:hover {
  transform: translateY(-5px);
}

.teacher h3 {
  margin-bottom: 5px;
  font-size: 1.8em;
}

.teacher h4 {
  margin-bottom: 10px;
  font-size: 1.2em;
  font-weight: 400;
  color: white;
}

.teacher p {
  margin-bottom: 12px;
  font-size: 1.1em;
}

/* Botão */
button {
  margin-top: 15px;
  padding: 12px 25px;
  background: white;
  color: #f01f84;;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: bold;
  transition: background 0.3s, transform 0.3s;
}

button:hover {
  transform: scale(1.05);
}
</style>
