import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/loginParts/Login.vue';
import Register from '../views/loginParts/Register.vue';
import Account from '../views/Account.vue';
import CalendarPage from '../views/avulso/CalendarPage.vue';
import SelectTimePage from '../views/avulso/SelectTimePage.vue';
import PlansPage from '@/views/PlansPage.vue';
import CancelPage from '../views/CancelPage.vue';
import PlanosDescricaoPage from '@/views/PlanosDescricaoPage.vue';
import store from '../store'; // Importe o Vuex store
import axios from 'axios';
import SelectDayPage from '../views/mensal/SelectDayPage.vue';
import SelectTimeMensalPage from '../views/mensal/SelectTimeMensalPage.vue';
import SuccessPage from '@/views/SuccessPage.vue';
import PrivacyPolicy from '@/views/loginParts/Privacy.vue'
import TermsOfService from '@/views/loginParts/Terms.vue'
import ResetPassword from '@/views/loginParts/ResetPassword.vue';
import ForgotPassword from '@/views/loginParts/ForgotPassword.vue';
import EmployeeDashboard from '@/views/employee/EmployeeDashboard.vue';
import EmployeeHorarios from '@/views/employee/EmployeeHorarios.vue';
import EmployeeSearchClients from '@/views/employee/EmployeeSearchClients.vue';
import ConfirmEmailPage from '@/views/loginParts/ConfirmEmailPage.vue';
import AulasPage from '@/views/Aulas.vue'
import AdmHorarios from '@/views/adm/AdmHorarios.vue';
import AdmDashboard from '@/views/adm/AdmDashboard.vue';
import SponsorsPage from '@/views/SponsorsPage.vue'
import AdmCreateUser from '@/views/adm/AdmCreateUser.vue';
import AdmCreateSponsor from '@/views/adm/AdmCreateSponsor.vue';
import AdmCreateCourt from '@/views/adm/AdmCreateCourt.vue';
import SuccessPlansPage from '@/views/SuccessPlansPage.vue';
import RenovarPlano from '@/views/RenovarPlano.vue';
import SuccessRenovacaoPage from '@/views/SuccessRenovacaoPage.vue';
import AdmSearchClients from '@/views/adm/AdmSearchClients.vue';
import AdmCreatePlans from '@/views/adm/plans/AdmCreatePlans.vue';
import AdmChoosePlan from '@/views/adm/plans/AdmChoosePlan.vue';
import AdmFinalPlans from '@/views/adm/plans/AdmFinalPlans.vue';
import AdmCheckPlans from '@/views/adm/AdmCheckPlans.vue';

const routes = [

  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: { requiresAuth: true }
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: CalendarPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/select-time',
    name: 'SelectTime',
    component: SelectTimePage,
    meta: { requiresAuth: true }
  },
  {
    path: '/planos',
    name: 'Planos',
    component: PlansPage,
  },
  {
    path: '/cancel',
    name: 'Cancel',
    component: CancelPage,
  },
  {
    path: '/planosView',
    name: 'PlanosDescricaoPage',
    component: PlanosDescricaoPage,
  },
  {
    path: '/dias',
    name: 'SelectDayPage',
    component: SelectDayPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/select-time-mensal',
    name: 'SelectTimeMensal',
    component: SelectTimeMensalPage,
    meta: { requiresAuth: true}
  },
  {
    path: '/success',
    name: 'SuccessPage',
    component: SuccessPage,
  },
  {
    path: '/success-plans',
    name: 'SuccessPlansPage',
    component: SuccessPlansPage,
  },
  {
    path: '/success-renovacao',
    name: 'SuccessRenovacaoPage',
    component: SuccessRenovacaoPage,
  },
  {
    path: '/terms',
    name: 'TermsOfService',
    component: TermsOfService,
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
  path: '/privacy',
  name: 'PrivacyPolicy',
  component: PrivacyPolicy,
},
{
  path: '/employee-dashboard',
  name: 'EmployeeDashboard',
  component: EmployeeDashboard,
  meta: { requiresAuth: true, requiresEmployee: true }
},
{
  path: '/employee-horarios',
  name: 'EmployeeHorarios',
  component: EmployeeHorarios,
  meta: { requiresAuth: true, requiresEmployee: true }
},
{
  path: '/employee-searchClients',
  name: 'EmployeeSearchClients',
  component: EmployeeSearchClients,
  meta: { requiresAuth: true, requiresEmployee: true }
},
{
  path: '/confirm-email',
  name: 'ConfirmEmailPage',
  component: ConfirmEmailPage,
},
{
  path: '/aulas',
  name: 'AulasPage',
  component: AulasPage,
},
{
  path: '/adm-horarios',
  name: 'AdmHorarios',
  component: AdmHorarios,
  meta: { requiresAuth: true, requiresAdmin: true }
},
{
  path: '/adm-dashboard',
  name: 'AdmDashboard',
  component: AdmDashboard,
  meta: { requiresAuth: true, requiresAdmin: true }
},
{
  path: '/parcerias',
  name: 'SponsorsPage',
  component: SponsorsPage,
},
{
  path: '/adm-usuarios',
  name: 'AdmCreateUser',
  component: AdmCreateUser,
  meta: { requiresAuth: true, requiresAdmin: true }
},
{
  path: '/adm-patrocinadores',
  name: 'AdmCreateSponsor',
  component: AdmCreateSponsor,
  meta: { requiresAuth: true, requiresAdmin: true }
},
{
  path: '/adm-quadras',
  name: 'AdmCreateCourt',
  component: AdmCreateCourt,
  meta: { requiresAuth: true, requiresAdmin: true }
},
{
  path: '/adm-search-clients',
  name: 'AdmSearchClients',
  component: AdmSearchClients,
  meta: { requiresAuth: true, requiresAdmin: true }
},
{
  path: '/adm-create-plans',
  name: 'AdmCreatePlans',
  component: AdmCreatePlans,
  meta: { requiresAuth: true, requiresAdmin: true }
},
{
  path: '/adm-choose-plan',
  name: 'AdmChoosePlan',
  component: AdmChoosePlan,
  meta: { requiresAuth: true, requiresAdmin: true }
},
{
  path: '/adm-final-plan',
  name: 'AdmFinalPlans',
  component: AdmFinalPlans,
  meta: { requiresAuth: true, requiresAdmin: true }
},
{
  path: '/adm-check-plans',
  name: 'AdmCheckPlans',
  component: AdmCheckPlans,
  meta: { requiresAuth: true, requiresAdmin: true }
},
{
  path: '/renovar-plano',
  name: 'RenovarPlano',
  component: RenovarPlano,
  meta: { requiresAuth: true}
},
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/account`, { withCredentials: true });
      const user = response.data.user;

      if (response.data.isAuthenticated) {
        store.commit('setAuthentication', true);
        store.commit('setUsuario', user);

        // Verifica permissões de administrador
        if (to.matched.some(record => record.meta.requiresAdmin) && !response.data.isAdm) {
          alert("Acesso restrito a administradores.");
          return next({ name: 'home' });
        }

        // Verifica permissões de funcionário
        if (to.matched.some(record => record.meta.requiresEmployee) && !response.data.isEmployee) {
          alert("Acesso restrito a funcionários.");
          return next({ name: 'home' });
        }

        return next(); // Usuário autenticado e com permissões adequadas
      } else {
        store.commit('setAuthentication', false);
        alert('Faça login primeiro.');
        return next({ name: 'login' });
      }
    } catch (error) {
      store.commit('setAuthentication', false);
      console.error('Erro ao verificar autenticação:', error); // Loga o erro para debugging
      alert('Faça login primeiro.');
      return next({ name: 'login' });
    }
  } else {
    next(); // Acesso permitido para rotas públicas
  }
});

export default router;
